import { default as _91_46_46_46token_93ZKUVm90nLnMeta } from "/home/igorFront/projects/smscodefront/pages/authorization-social/[...token].vue?macro=true";
import { default as indexbFWdI83b5UMeta } from "/home/igorFront/projects/smscodefront/pages/authorization/index.vue?macro=true";
import { default as indexrjvBvnoVakMeta } from "/home/igorFront/projects/smscodefront/pages/cabinet/api/index.vue?macro=true";
import { default as indexs1KE1fefxLMeta } from "/home/igorFront/projects/smscodefront/pages/cabinet/index.vue?macro=true";
import { default as indexgSwjDiDQw5Meta } from "/home/igorFront/projects/smscodefront/pages/cabinet/numbers-history/index.vue?macro=true";
import { default as indexqhR9XYW4q5Meta } from "/home/igorFront/projects/smscodefront/pages/cabinet/payments/index.vue?macro=true";
import { default as index0sgV1CkanVMeta } from "/home/igorFront/projects/smscodefront/pages/cabinet/profile/index.vue?macro=true";
import { default as indexPdbiORjrNJMeta } from "/home/igorFront/projects/smscodefront/pages/forgot-password/index.vue?macro=true";
import { default as indexhx4atKBRixMeta } from "/home/igorFront/projects/smscodefront/pages/index.vue?macro=true";
import { default as indexAchy54H3DvMeta } from "/home/igorFront/projects/smscodefront/pages/payment/result/index.vue?macro=true";
import { default as indexYfI7qXHrs0Meta } from "/home/igorFront/projects/smscodefront/pages/registration/index.vue?macro=true";
import { default as _91_46_46_46token_93OZq3QSUYtxMeta } from "/home/igorFront/projects/smscodefront/pages/reset-password/[...token].vue?macro=true";
export default [
  {
    name: _91_46_46_46token_93ZKUVm90nLnMeta?.name ?? "authorization-social-token___en___default",
    path: _91_46_46_46token_93ZKUVm90nLnMeta?.path ?? "/authorization-social/:token(.*)*",
    meta: _91_46_46_46token_93ZKUVm90nLnMeta || {},
    alias: _91_46_46_46token_93ZKUVm90nLnMeta?.alias || [],
    redirect: _91_46_46_46token_93ZKUVm90nLnMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/authorization-social/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93ZKUVm90nLnMeta?.name ?? "authorization-social-token___en",
    path: _91_46_46_46token_93ZKUVm90nLnMeta?.path ?? "/en/authorization-social/:token(.*)*",
    meta: _91_46_46_46token_93ZKUVm90nLnMeta || {},
    alias: _91_46_46_46token_93ZKUVm90nLnMeta?.alias || [],
    redirect: _91_46_46_46token_93ZKUVm90nLnMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/authorization-social/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93ZKUVm90nLnMeta?.name ?? "authorization-social-token___ru",
    path: _91_46_46_46token_93ZKUVm90nLnMeta?.path ?? "/ru/authorization-social/:token(.*)*",
    meta: _91_46_46_46token_93ZKUVm90nLnMeta || {},
    alias: _91_46_46_46token_93ZKUVm90nLnMeta?.alias || [],
    redirect: _91_46_46_46token_93ZKUVm90nLnMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/authorization-social/[...token].vue").then(m => m.default || m)
  },
  {
    name: indexbFWdI83b5UMeta?.name ?? "authorization___en___default",
    path: indexbFWdI83b5UMeta?.path ?? "/authorization",
    meta: indexbFWdI83b5UMeta || {},
    alias: indexbFWdI83b5UMeta?.alias || [],
    redirect: indexbFWdI83b5UMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexbFWdI83b5UMeta?.name ?? "authorization___en",
    path: indexbFWdI83b5UMeta?.path ?? "/en/authorization",
    meta: indexbFWdI83b5UMeta || {},
    alias: indexbFWdI83b5UMeta?.alias || [],
    redirect: indexbFWdI83b5UMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexbFWdI83b5UMeta?.name ?? "authorization___ru",
    path: indexbFWdI83b5UMeta?.path ?? "/ru/authorization",
    meta: indexbFWdI83b5UMeta || {},
    alias: indexbFWdI83b5UMeta?.alias || [],
    redirect: indexbFWdI83b5UMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexrjvBvnoVakMeta?.name ?? "cabinet-api___en___default",
    path: indexrjvBvnoVakMeta?.path ?? "/cabinet/api",
    meta: indexrjvBvnoVakMeta || {},
    alias: indexrjvBvnoVakMeta?.alias || [],
    redirect: indexrjvBvnoVakMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: indexrjvBvnoVakMeta?.name ?? "cabinet-api___en",
    path: indexrjvBvnoVakMeta?.path ?? "/en/cabinet/api",
    meta: indexrjvBvnoVakMeta || {},
    alias: indexrjvBvnoVakMeta?.alias || [],
    redirect: indexrjvBvnoVakMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: indexrjvBvnoVakMeta?.name ?? "cabinet-api___ru",
    path: indexrjvBvnoVakMeta?.path ?? "/ru/cabinet/api",
    meta: indexrjvBvnoVakMeta || {},
    alias: indexrjvBvnoVakMeta?.alias || [],
    redirect: indexrjvBvnoVakMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: indexs1KE1fefxLMeta?.name ?? "cabinet___en___default",
    path: indexs1KE1fefxLMeta?.path ?? "/cabinet",
    meta: indexs1KE1fefxLMeta || {},
    alias: indexs1KE1fefxLMeta?.alias || [],
    redirect: indexs1KE1fefxLMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexs1KE1fefxLMeta?.name ?? "cabinet___en",
    path: indexs1KE1fefxLMeta?.path ?? "/en/cabinet",
    meta: indexs1KE1fefxLMeta || {},
    alias: indexs1KE1fefxLMeta?.alias || [],
    redirect: indexs1KE1fefxLMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexs1KE1fefxLMeta?.name ?? "cabinet___ru",
    path: indexs1KE1fefxLMeta?.path ?? "/ru/cabinet",
    meta: indexs1KE1fefxLMeta || {},
    alias: indexs1KE1fefxLMeta?.alias || [],
    redirect: indexs1KE1fefxLMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexgSwjDiDQw5Meta?.name ?? "cabinet-numbers-history___en___default",
    path: indexgSwjDiDQw5Meta?.path ?? "/cabinet/numbers-history",
    meta: indexgSwjDiDQw5Meta || {},
    alias: indexgSwjDiDQw5Meta?.alias || [],
    redirect: indexgSwjDiDQw5Meta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexgSwjDiDQw5Meta?.name ?? "cabinet-numbers-history___en",
    path: indexgSwjDiDQw5Meta?.path ?? "/en/cabinet/numbers-history",
    meta: indexgSwjDiDQw5Meta || {},
    alias: indexgSwjDiDQw5Meta?.alias || [],
    redirect: indexgSwjDiDQw5Meta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexgSwjDiDQw5Meta?.name ?? "cabinet-numbers-history___ru",
    path: indexgSwjDiDQw5Meta?.path ?? "/ru/cabinet/numbers-history",
    meta: indexgSwjDiDQw5Meta || {},
    alias: indexgSwjDiDQw5Meta?.alias || [],
    redirect: indexgSwjDiDQw5Meta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexqhR9XYW4q5Meta?.name ?? "cabinet-payments___en___default",
    path: indexqhR9XYW4q5Meta?.path ?? "/cabinet/payments",
    meta: indexqhR9XYW4q5Meta || {},
    alias: indexqhR9XYW4q5Meta?.alias || [],
    redirect: indexqhR9XYW4q5Meta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexqhR9XYW4q5Meta?.name ?? "cabinet-payments___en",
    path: indexqhR9XYW4q5Meta?.path ?? "/en/cabinet/payments",
    meta: indexqhR9XYW4q5Meta || {},
    alias: indexqhR9XYW4q5Meta?.alias || [],
    redirect: indexqhR9XYW4q5Meta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexqhR9XYW4q5Meta?.name ?? "cabinet-payments___ru",
    path: indexqhR9XYW4q5Meta?.path ?? "/ru/cabinet/payments",
    meta: indexqhR9XYW4q5Meta || {},
    alias: indexqhR9XYW4q5Meta?.alias || [],
    redirect: indexqhR9XYW4q5Meta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: index0sgV1CkanVMeta?.name ?? "cabinet-profile___en___default",
    path: index0sgV1CkanVMeta?.path ?? "/cabinet/profile",
    meta: index0sgV1CkanVMeta || {},
    alias: index0sgV1CkanVMeta?.alias || [],
    redirect: index0sgV1CkanVMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: index0sgV1CkanVMeta?.name ?? "cabinet-profile___en",
    path: index0sgV1CkanVMeta?.path ?? "/en/cabinet/profile",
    meta: index0sgV1CkanVMeta || {},
    alias: index0sgV1CkanVMeta?.alias || [],
    redirect: index0sgV1CkanVMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: index0sgV1CkanVMeta?.name ?? "cabinet-profile___ru",
    path: index0sgV1CkanVMeta?.path ?? "/ru/cabinet/profile",
    meta: index0sgV1CkanVMeta || {},
    alias: index0sgV1CkanVMeta?.alias || [],
    redirect: index0sgV1CkanVMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexPdbiORjrNJMeta?.name ?? "forgot-password___en___default",
    path: indexPdbiORjrNJMeta?.path ?? "/forgot-password",
    meta: indexPdbiORjrNJMeta || {},
    alias: indexPdbiORjrNJMeta?.alias || [],
    redirect: indexPdbiORjrNJMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexPdbiORjrNJMeta?.name ?? "forgot-password___en",
    path: indexPdbiORjrNJMeta?.path ?? "/en/forgot-password",
    meta: indexPdbiORjrNJMeta || {},
    alias: indexPdbiORjrNJMeta?.alias || [],
    redirect: indexPdbiORjrNJMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexPdbiORjrNJMeta?.name ?? "forgot-password___ru",
    path: indexPdbiORjrNJMeta?.path ?? "/ru/forgot-password",
    meta: indexPdbiORjrNJMeta || {},
    alias: indexPdbiORjrNJMeta?.alias || [],
    redirect: indexPdbiORjrNJMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexhx4atKBRixMeta?.name ?? "index___en___default",
    path: indexhx4atKBRixMeta?.path ?? "/",
    meta: indexhx4atKBRixMeta || {},
    alias: indexhx4atKBRixMeta?.alias || [],
    redirect: indexhx4atKBRixMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexhx4atKBRixMeta?.name ?? "index___en",
    path: indexhx4atKBRixMeta?.path ?? "/en",
    meta: indexhx4atKBRixMeta || {},
    alias: indexhx4atKBRixMeta?.alias || [],
    redirect: indexhx4atKBRixMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexhx4atKBRixMeta?.name ?? "index___ru",
    path: indexhx4atKBRixMeta?.path ?? "/ru",
    meta: indexhx4atKBRixMeta || {},
    alias: indexhx4atKBRixMeta?.alias || [],
    redirect: indexhx4atKBRixMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexAchy54H3DvMeta?.name ?? "payment-result___en___default",
    path: indexAchy54H3DvMeta?.path ?? "/payment/result",
    meta: indexAchy54H3DvMeta || {},
    alias: indexAchy54H3DvMeta?.alias || [],
    redirect: indexAchy54H3DvMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: indexAchy54H3DvMeta?.name ?? "payment-result___en",
    path: indexAchy54H3DvMeta?.path ?? "/en/payment/result",
    meta: indexAchy54H3DvMeta || {},
    alias: indexAchy54H3DvMeta?.alias || [],
    redirect: indexAchy54H3DvMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: indexAchy54H3DvMeta?.name ?? "payment-result___ru",
    path: indexAchy54H3DvMeta?.path ?? "/ru/payment/result",
    meta: indexAchy54H3DvMeta || {},
    alias: indexAchy54H3DvMeta?.alias || [],
    redirect: indexAchy54H3DvMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: indexYfI7qXHrs0Meta?.name ?? "registration___en___default",
    path: indexYfI7qXHrs0Meta?.path ?? "/registration",
    meta: indexYfI7qXHrs0Meta || {},
    alias: indexYfI7qXHrs0Meta?.alias || [],
    redirect: indexYfI7qXHrs0Meta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexYfI7qXHrs0Meta?.name ?? "registration___en",
    path: indexYfI7qXHrs0Meta?.path ?? "/en/registration",
    meta: indexYfI7qXHrs0Meta || {},
    alias: indexYfI7qXHrs0Meta?.alias || [],
    redirect: indexYfI7qXHrs0Meta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexYfI7qXHrs0Meta?.name ?? "registration___ru",
    path: indexYfI7qXHrs0Meta?.path ?? "/ru/registration",
    meta: indexYfI7qXHrs0Meta || {},
    alias: indexYfI7qXHrs0Meta?.alias || [],
    redirect: indexYfI7qXHrs0Meta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93OZq3QSUYtxMeta?.name ?? "reset-password-token___en___default",
    path: _91_46_46_46token_93OZq3QSUYtxMeta?.path ?? "/reset-password/:token(.*)*",
    meta: _91_46_46_46token_93OZq3QSUYtxMeta || {},
    alias: _91_46_46_46token_93OZq3QSUYtxMeta?.alias || [],
    redirect: _91_46_46_46token_93OZq3QSUYtxMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/reset-password/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93OZq3QSUYtxMeta?.name ?? "reset-password-token___en",
    path: _91_46_46_46token_93OZq3QSUYtxMeta?.path ?? "/en/reset-password/:token(.*)*",
    meta: _91_46_46_46token_93OZq3QSUYtxMeta || {},
    alias: _91_46_46_46token_93OZq3QSUYtxMeta?.alias || [],
    redirect: _91_46_46_46token_93OZq3QSUYtxMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/reset-password/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93OZq3QSUYtxMeta?.name ?? "reset-password-token___ru",
    path: _91_46_46_46token_93OZq3QSUYtxMeta?.path ?? "/ru/reset-password/:token(.*)*",
    meta: _91_46_46_46token_93OZq3QSUYtxMeta || {},
    alias: _91_46_46_46token_93OZq3QSUYtxMeta?.alias || [],
    redirect: _91_46_46_46token_93OZq3QSUYtxMeta?.redirect,
    component: () => import("/home/igorFront/projects/smscodefront/pages/reset-password/[...token].vue").then(m => m.default || m)
  }
]